"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EXTERNAL_CONTENT_TYPE = void 0;

var _client = require("@apollo/client");

var _react = _interopRequireDefault(require("react"));

var _graphCms = _interopRequireDefault(require("./graphCMS/graph.cms.provider"));

const EXTERNAL_CONTENT_TYPE = {
  HEADER: 'header',
  FOOTER: 'footer',
  HOME_PAGE: 'homePage',
  OFFER_PAGE: 'offerPage',
  LEGAL: 'legal'
};
exports.EXTERNAL_CONTENT_TYPE = EXTERNAL_CONTENT_TYPE;

const useExternalContent = (contentType, variables) => {
  const externalProvider = _react.default.useMemo(() => new _graphCms.default(), []);

  const contentQuery = externalProvider.getContent(contentType);
  const {
    data,
    error,
    loading
  } = (0, _client.useQuery)((0, _client.gql)`
      ${contentQuery}
    `, variables);
  return {
    data,
    error,
    loading
  };
};

var _default = useExternalContent;
exports.default = _default;