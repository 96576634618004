"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useExternalContent = useExternalContent;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _client = require("@apollo/client");

var _react = _interopRequireDefault(require("react"));

var _graphCmsProvider = _interopRequireDefault(require("./graphCmsProvider"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function useExternalContent(contentType, options) {
  const externalProvider = _react.default.useMemo(() => new _graphCmsProvider.default(), []);

  const contentQuery = externalProvider.getContent(contentType);

  const onError = error => {
    if (options !== null && options !== void 0 && options.onError) {
      options === null || options === void 0 ? void 0 : options.onError(Error(error.message));
    }
  };

  const _useQuery = (0, _client.useQuery)((0, _client.gql)`
      ${contentQuery}
    `, {
    variables: options === null || options === void 0 ? void 0 : options.variables,
    onCompleted: options === null || options === void 0 ? void 0 : options.onCompleted,
    onError
  }),
        {
    error: apolloError,
    refetch: apolloRefetch
  } = _useQuery,
        rest = (0, _objectWithoutProperties2.default)(_useQuery, ["error", "refetch"]);

  const error = new Error(apolloError === null || apolloError === void 0 ? void 0 : apolloError.message);
  return _objectSpread({
    error,
    refetch: apolloRefetch
  }, rest);
}